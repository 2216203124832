<template>
  <div
    class="profile-content d-flex align-items-center justify-content-center align-items-lg-end justify-content-lg-between flex-column flex-lg-row h-100"
  >
    <div
      class="image-and-info d-flex align-items-lg-end gap_2 flex-lg-row flex-column align-items-center justify-content-center"
    >
      <div class="image-profile">
        <img
          :src="storeHeader.logo"
          :alt="storeHeader.name"
          :title="storeHeader.name"
          class="img-fluid"
        />
      </div>
      <p class="font-size-22 font-weight-bold text-white">
        {{ storeHeader.name }}
      </p>
    </div>
    <div class="share-wrapper d-flex icons" style="position: relative">
      <div class="share-icon d-flex position-relative">
        <button
          class="bg-transparent border-0 p-0"
          @click.prevent="openDivFunction('myDIV')"
        >
          <i class="las la-link"></i>
        </button>
        <div id="myDIV" class="link-store shadow">
          <span
            class="close-store"
            @click="shareFunction('myDIV')"
            aria-hidden="true"
            >×</span
          >
          <div class="col-12 px-0">
            <h2 style="font-size: 16px">{{ $t("main.link") }}:</h2>
            <div class="input-container">
              <i class="las la-copy" @click="shareLink"></i>
              <input
                readonly=""
                class="input-field"
                type="text"
                v-model="storePath"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="social-icons">
        <a :href="storeHeader.social_media_links.facebook" target="_blank">
          <i class="lab la-facebook-f"></i
        ></a>
        <a :href="storeHeader.social_media_links.twitter" target="_blank">
          <i class="lab la-twitter"></i
        ></a>
        <a :href="storeHeader.social_media_links.instagram" target="_blank">
          <i class="lab la-instagram"></i
        ></a>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
// import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  props: ["profileImage", "storeHeader"],
  data() {
    return {
      totalProducts: null,
      storePath:
        window.location.origin +
        window.location.pathname
          .replace("/privacy-store", "")
          .replace("/about-store", ""),
      storeShortPath: window.location.origin + "/c/afgh",
    };
  },
  methods: {
    // getAllProducts () {
    //   // this.loadingProducts = true
    //   marketPlace.getAllProducts(this.$route.params.slogan, this.selectSort).then(res => {
    //     this.totalProducts = res.data.meta.totalItems
    //   }).finally(() => {
    //     // this.loadingProducts = false
    //   })
    // },
    shareLink() {
      console.log(window.location);
      navigator.clipboard.writeText(this.storePath);
      core.showSnackbar("success", "تم نسخ الرابط بنجاح");
    },
    shareShortLink() {
      console.log(window.location);
      navigator.clipboard.writeText(this.storeShortPath);
      core.showSnackbar("success", "تم نسخ الرابط بنجاح");
    },
    shareFunction(id) {
      var x = document.getElementById(id);
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    openDivFunction(id) {
      var x = document.getElementById(id);
      x.style.display = "block";
    },
  },
  created() {
    // this.getAllProducts()
  },
};
</script>
<style lang="scss" scoped>
.profile-content {
  position: relative;
  z-index: 5;
}

.details {
  p {
    margin: 0 !important;
  }
}

.profile-tab {
  cursor: pointer;
}

.header-image {
  color: var(--iq-white) !important;
  text-align: initial !important;
}

.image-profile {
  position: relative;
  top: 47px;
}

.icons,
.image-and-info {
  margin-bottom: 1rem;
}
.icons {
  i {
    border-radius: 2px !important;
  }
}
.mobile-icons {
  display: none;
}

.star-icons .rate-number {
  color: var(--iq-white) !important;
}

.profile-icon-header {
  color: var(--iq-white) !important;
  font-size: 18px;
}

@media (max-width: 991.98px) {
  .image-and-info {
    width: 100%;
    gap: 0;
    margin-bottom: 0;
  }
  .image-profile {
    width: 120px !important;
    height: 120px !important;
    top: 0;
  }
  .header-image {
    color: var(--iq-primary) !important;
    font-size: 24px !important;
    text-align: center !important;
  }
  .icons {
    display: block !important;
    text-align: center;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    i {
      border-radius: 2px !important;
    }
  }
  .star-icons {
    display: block !important;
    text-align: center !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .star-icons .rate-number {
    color: var(--iq-secondary-dark) !important;
  }
  .profile-icon-header {
    color: var(--iq-primary) !important;
    font-size: 20px;
  }
  .profile-tab {
    width: 100% !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    gap: 0 !important;
  }
  .profile-tab .tab_list {
    border-radius: 0 !important;
    padding: 20px 22px;
  }
}

.link-store {
  bottom: 0px;
  position: absolute;
  margin-left: 65px;
  display: none;
  background-color: #f2f2f2;
  left: 0;
  width: 335px;
  /* height: 110px; */
  border-radius: 2px;
  padding: 20px;
  z-index: 10000;
  &:lang(en) {
    margin-left: 0;
    margin-right: 65px;
    left: auto;
    right: 0;
  }
}

.link-store .icon,
.link-product .icon {
  background: #8ab630;
  color: white;
  min-width: 50px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.close-store {
  float: left;
  width: 20px;
  height: 20px;
  line-height: 21px;
  text-align: center;
  color: #00443d;
  margin-top: -5px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
  z-index: 55;
  position: relative;
}

.link-store h2,
.link-product h2 {
  color: #6a6869;
  font-size: 16px;
}

.link-store .input-container,
.link-product .input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

link-store .icon,
.link-product .icon {
  background: #8ab630;
  color: white;
  min-width: 50px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.link-store .input-field,
.link-product .input-field {
  width: 100%;
  padding: 6px;
  outline: none;
  direction: ltr;
  border-radius: 5px;
}

/*@media (min-width: 991.98px) and (max-width: 1199.98px){*/
/*.header-image{*/
/*font-size: 24px !important;*/
/*  margin-left: 0 !important;*/
/*  padding-right: 0 !important;*/
/*}*/
/*}*/
</style>
