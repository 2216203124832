var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"for-margin-top"},[(_vm.allCategory)?_c('b-card',[_c('ul',{staticClass:"p-0 m-0"},[_c('li',{class:[
          'cursor-pointer category-item',
          `${
            _vm.$route.query.filter === '' || _vm.$route.query.filter === undefined
              ? 'activeFilter'
              : ''
          }`,
        ],on:{"click":function($event){return _vm.$router.push({ name: 'storeProfile', query: { filter: '' } })}}},[_c('span',{staticClass:"font-size-22"},[_vm._v(_vm._s(_vm.$t("main.all")))])]),_vm._l((_vm.allCategory),function(item,i){return _c('li',{key:i,class:[
          'cursor-pointer category-item',
          `${_vm.$route.query.filter === item.id ? 'activeFilter' : ''}`,
        ],on:{"click":function($event){return _vm.$router.push({ name: 'storeProfile', query: { filter: item.id } })}}},[_c('span',{staticClass:"font-size-22"},[_vm._v(_vm._s(item.name))])])})],2)]):_vm._e(),_c('b-card',{staticClass:"link"},[_c('router-link',{staticClass:"tab_list",attrs:{"to":{ name: 'aboutStore' },"tag":"li","exact":""}},[_c('img',{staticClass:"section-icon",attrs:{"src":require("@/assets/images/icons/info.svg")}}),_c('span',[_vm._v(_vm._s(_vm.$t("marketplace.aboutStore")))])])],1),_c('b-card',{staticClass:"link"},[_c('router-link',{staticClass:"tab_list",attrs:{"to":{ name: 'privacyPolicyStore' },"tag":"li","exact":""}},[_c('img',{staticClass:"section-icon",attrs:{"src":require("@/assets/images/icons/contract.svg")}}),_c('span',[_vm._v(_vm._s(_vm.$t("marketplace.TermsAndConditions")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }