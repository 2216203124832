<template>
  <div class="for-margin-top">
    <!--    <b-card class="margin-bottom">-->
    <!--      <div class="d-flex align-items-center section-item">-->
    <!--        <p>الأقسام</p>-->
    <!--      </div>-->
    <!--    </b-card>-->
    <b-card v-if="allCategory">
      <ul class="p-0 m-0">
        <li
          :class="[
            'cursor-pointer category-item',
            `${
              $route.query.filter === '' || $route.query.filter === undefined
                ? 'activeFilter'
                : ''
            }`,
          ]"
          @click="$router.push({ name: 'storeProfile', query: { filter: '' } })"
        >
          <span class="font-size-22">{{ $t("main.all") }}</span>
        </li>
        <li
          :class="[
            'cursor-pointer category-item',
            `${$route.query.filter === item.id ? 'activeFilter' : ''}`,
          ]"
          v-for="(item, i) in allCategory"
          :key="i"
          @click="
            $router.push({ name: 'storeProfile', query: { filter: item.id } })
          "
        >
          <span class="font-size-22">{{ item.name }}</span>
        </li>
      </ul>
    </b-card>
    <b-card class="link">
      <router-link :to="{ name: 'aboutStore' }" class="tab_list" tag="li" exact
        ><img
          src="@/assets/images/icons/info.svg"
          class="section-icon"
        /><span>{{ $t("marketplace.aboutStore") }}</span>
      </router-link>
    </b-card>
    <b-card class="link">
      <router-link
        :to="{ name: 'privacyPolicyStore' }"
        class="tab_list"
        tag="li"
        exact
      >
        <img src="@/assets/images/icons/contract.svg" class="section-icon" />
        <span>{{ $t("marketplace.TermsAndConditions") }}</span>
      </router-link>
    </b-card>
  </div>
</template>

<script>
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";

export default {
  name: "storeCategories",
  data() {
    return {
      allCategory: [],
    };
  },
  methods: {
    getAllCategoryStore() {
      this.loadCat = true;
      marketPlace
        .getAllStoresGategories()
        .then((res) => {
          this.allCategory = res.data.data;
        })
        .finally(() => {
          this.loadCat = false;
        });
    },
  },
  created() {
    this.getAllCategoryStore();
  },
};
</script>

<style lang="scss" scoped>
.for-margin-top {
  margin-top: 4rem;
}

.section-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1rem;
  border-radius: 2px;

  p {
    margin: 0 0.5rem;
    color: #000000;
    font-weight: 700;
    font-size: 1.1rem;
  }
}

.margin-bottom {
  margin-bottom: 1.3rem;
}

.section-icon {
  width: 18px;
  height: 18px;
}

.category-item {
  padding: 0.7rem 1rem;
  transition: all 0.4s ease-in-out;
  color: #000000;
  font-weight: 500;
  position: relative;

  &:after {
    content: "";
    width: 90%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #e4e4e4;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
}

.link {
  margin-top: 1.3rem;

  span {
    color: #000000;
    font-size: 1.2rem;
  }

  &:last-of-type {
    margin-bottom: 1.3rem;
  }
}

.tab_list {
  padding: 0.7rem 1rem;
  list-style: none;
  box-shadow: none;
  cursor: pointer;
  border-radius: 0;
  transition: all 0.4s ease-in-out;

  span {
    display: inline-block;
    margin: 0 0.5rem;
  }

  &:hover {
    background-color: #b7b7b7 !important;
    color: #000000 !important;
  }
}

.category-item:hover {
  background-color: #b7b7b7;
  color: #000000;
}

.category-item:active,
.activeFilter {
  background-color: #b7b7b7;
  font-weight: bold;
  color: #000000;
}

.tab_list.router-link-active {
  background-color: #b7b7b7;
  font-weight: bold;
  color: #000000;

  &:after {
    display: none;
  }
}
</style>
