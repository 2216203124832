<template>
  <div v-if="!loadingStore">
    <div
      class="cover-store-profile"
      :style="{
        background: 'url(' + allStoreInfo.cover + ')',
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
      }"
    >
      <div class="overlay-cover"></div>
      <b-container class="h-100">
        <header-profile
          :profileImage="allStoreInfo"
          :storeHeader="allStoreInfo"
        />
      </b-container>
    </div>
    <main class="main-profile">
      <b-container>
        <transition
          name="router-anim"
          :enter-active-class="`animated fadeInUp`"
          mode="out-in"
          :leave-active-class="`animated fadeOut`"
        >
          <b-row>
            <b-col lg="3">
              <store-categories />
            </b-col>
            <b-col lg="9">
              <router-view :storeInfo="allStoreInfo"></router-view>
            </b-col>
          </b-row>
        </transition>
      </b-container>
    </main>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center mt-5">
    <b-spinner large type="grow" variant="primary"></b-spinner>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import headerProfile from "@/modules/servicePages/marketplace/components/profile/headerProfile";
import storeCategories from "@/modules/servicePages/marketplace/components/profile/storeCategories";
export default {
  components: { headerProfile, storeCategories },
  data() {
    return {
      coverImage: require("@/assets/images/ibbil/ibbil-cover.jpg"),
      profileImage: require("@/assets/images/ibbil/ibbil-cover.jpg"),
      allStoreInfo: {},
      loadingStore: true,
    };
  },
  methods: {
    getStoreInfo() {
      this.loadingStore = true;
      marketPlace
        .getStoreInfo(this.$route.params.id)
        .then((res) => {
          this.allStoreInfo = res.data.data;
        })
        .finally(() => {
          this.loadingStore = false;
        });
    },
  },
  created() {
    this.getStoreInfo();
  },
  mounted() {
    core.index();
  },
};
</script>
<style lang="scss">
.image-profile {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}
.cover-store-profile {
  width: 100%;
  height: 325px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  .overlay-cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 30%,
      rgba(0, 0, 0, 0) 70%
    );
  }
}
.star-icons img {
  width: 18px;
}
.tab_list {
  background: transparent;
  padding: 13px 25px;
  border-radius: 0px 0px 15px 15px;
  color: #646464;
  box-shadow: 0px 3px 18px #00000029;
  margin-bottom: 0;
}
.tab_list.router-link-active {
  background: #fff;
  position: relative;
  overflow: hidden;
}
.tab_list.router-link-active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: var(--iq-primary);
  content: "";
}
.icons i {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
  color: var(--iq-primary);
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 3px 18px #00000029;
}
.active-categories {
  box-shadow: 0px 3px 18px #5cba8c6a;
  border: 3px solid #52c98f;
}
.padding-all-categories {
  padding: 0px 64px !important;
}
.shadow-categories {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 18px #00000029;
}
.arrow-category span {
  z-index: 100;
}
.arrow-category span .icon-category {
  color: #ffffff !important;
  font-size: 24px;
  width: 42px;
  height: 42px;
  background-color: #3f6854;
  border-radius: 50%;
  text-align: center;
}
.left-20 {
  left: -20px !important;
}
.category-image-background {
  background-size: cover;
  background-position: center;
  width: 111px;
  height: 111px;
  background-color: var(--iq-white);
}
.iq-card {
  margin-bottom: 1.3rem !important;
}
</style>
